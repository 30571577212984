
function UpdateAuthTokens()
{
// eslint-disable-next-line no-restricted-globals
self.onmessage = event => {
    // eslint-disable-next-line no-restricted-globals
     const tokenEndpoint = new URL(
        event.data.tokenUrl
      );
      tokenEndpoint.searchParams.append('grant_type', 'refresh_token');
      tokenEndpoint.searchParams.append('refresh_token', event.data.token  );
      const hHeaders = new Headers();
      hHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      hHeaders.append("Accept", "application/json");
      var fetchOptions:any = {}
      fetchOptions.method = "POST";
      fetchOptions.headers = hHeaders;
      fetchOptions.body = tokenEndpoint.searchParams;
      try{
       fetch(event.data.tokenUrl, fetchOptions)
      .then(
        response => {
            return response.json();
        })
        .then(function(_ref) {
                   
                        const hHeaders = new Headers();
                        hHeaders.append("Accept", "application/json");
                        hHeaders.append("Authorization", "Bearer " + _ref.id_token);
                        var fetchOptions:any = {}
                        fetchOptions.method = "GET";
                        fetchOptions.headers = hHeaders;
                        fetch(event.data.STSUrl + "Tenant/" + event.data.tenant.key + "/AcquireToken", fetchOptions)
                        .then(
                            response1 => {
                                return response1.json();
                            })
                        .then(function(_ref1) {
                            _ref.tenantAuth = _ref1;
                            postMessage({"error":false,"data":_ref});
                        })
                        .catch((err)=>{
                            postMessage({"error":true,"data":err});console.log(err);
                        })
        }).catch((err)=>{
            postMessage({"error":true,"data":err});console.log(err);
      });
      }
     catch(err){
        postMessage({"error":true,"data":err});
        console.log(err);
     }
  
  
  }


}

export  default UpdateAuthTokens;
 