import { useState } from "react";

export function useContextMenu() {
    const [contextMenuState, setContextMenuState] = useState<{keys:number[],visible:boolean,x:number,y:number}>({ keys:[], visible: false, x: 0, y: 0 });

    function closeContextMenu() {
        setContextMenuState({ keys:[], visible: false, x: 0, y: 0 })
    }

    function onContextMenu(event: React.MouseEvent) {
        event.preventDefault();
        setContextMenuState({ keys:[], visible: true, x: event.pageX, y: event.pageY })
    }

    return { contextMenuState, setContextMenuState, onContextMenu, closeContextMenu }
}