
function SendInlineAttachToS3()
{
// eslint-disable-next-line no-restricted-globals
self.onmessage = event => {
    // eslint-disable-next-line no-restricted-globals
     const tokenEndpoint = new URL(
        event.data.tokenUrl
      );
      const hHeaders = new Headers();
      hHeaders.append("Content-Type", "multipart/form-data");
      var fetchOptions:any = {}
      fetchOptions.method = "PUT";
      fetchOptions.headers = hHeaders;
      fetchOptions.body = event.data.file;
      try{
       fetch(event.data.S3URL, fetchOptions)
      .then(
        response => {
            if(response.ok){
                postMessage({"error":false,"data":"success"})
            
            }
            else{
                postMessage({"error":true,"data": (response.status + " - " +response.statusText) });console.log(response.statusText);
            
            }
        }); 
      }
     catch(err){
        postMessage({"error":true,"data":err});
        console.log(err);
     }
  
  
  }


}

export  default SendInlineAttachToS3;
 