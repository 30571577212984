import store from "../state/store";
import { getLocalValue } from "../utilities/localStore/calls";

export interface userPreferences{
    general: generalPreferances;
}

export interface generalPreferances{
        enquiryList: general_enquiryList
}

export interface general_enquiryList{
    multiSelectMode:  "multi-preview"| "multi-standard";
}

function createDefaultPreference(): userPreferences{
    return {
        general:{
            enquiryList:{
                multiSelectMode:"multi-preview"
            }
        }
    } 
}

export function getAllPreferences():userPreferences{
    let userPreferance = getLocalValue<userPreferences>("userPreferances");
    const defaultPrefs = createDefaultPreference()
    if(userPreferance){
        userPreferance = recursiveMap(defaultPrefs,userPreferance);
    }else{
        userPreferance = defaultPrefs;
    }
        return userPreferance;

}


function recursiveMap<TMappedObject>(initValue: TMappedObject, target: TMappedObject): TMappedObject {
    for (const key in initValue) {
        if(!target[key]) {
            target[key] = initValue[key];
        }
        else if (typeof initValue[key] === 'object') {
            recursiveMap(initValue[key], target[key]);
        }
    }
    return target;
}