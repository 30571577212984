import React from 'react';
import { createContext, useState } from 'react';

 


export interface gridSelectionContext{
    selectionProperties: gridSelectionProperties,
    SetEnquiryGridSelectionProperties: React.Dispatch<React.SetStateAction<{multiSelect: boolean,
        selectedEnquiries : string[];
    }>>;
}

export const initialGridSelectionContext:gridSelectionContext = {
    selectionProperties:{ multiSelect: false,
    selectedEnquiries : []},
    SetEnquiryGridSelectionProperties: ()=>{},
}

export interface gridSelectionProperties{
    multiSelect: boolean;
    selectedEnquiries : string[];
}

export function updateGridSelection(){
     
}

export const EnquiryGridSelectionContext = createContext(initialGridSelectionContext);

