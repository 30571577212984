import { callApi, iRequest,Post,Get } from "../../utilities/apiCall";
import { iLastTouched } from "../../interfaces/lastTouched";
import { iQueue, iQueues, setQState } from "../../state/queueSlice";
import store, { RootState } from "../../state/store";
import { setEmailVisible } from "../../state/secondColSlice";
import { setQueuesVisibility } from "../../state/siteSlice";
import { switchFirstTab } from "../../state/tabRow";
import { setItemEnabled } from "../../utilities/loading";
import { sortComms, sortEnquiriesByField, sortQueues } from "../../utilities/sort";
import { iEnquiries, iEnquiry } from "../../interfaces/iEnquiry";
import { setActiveEnquiry } from "../../state/activeEnquiry";
import { useSelector } from "react-redux";
import { iUser } from "../../interfaces/iUser";
import { EnquiryCache, getEnquiriesMap, saveActiveListEnquiries, saveEnquiriesMap, saveSessionValue } from "../../utilities/localStore/calls";
import { getLastTouched, saveLastTouched } from "../LastTouched/misc";
import { updateEnquiriesCache } from "../../state/enquiriesCache";
export async function getQueuesInfo() {
    const iLastTouched = getLastTouched();
    if(iLastTouched.Queue.EnquiryLists.length==0){
        alert("no queues assigned to this user");
    }else{
        iLastTouched.Queue.ActiveList = iLastTouched.Queue.ActiveList==0? iLastTouched.Queue.EnquiryLists[0].Name: iLastTouched.Queue.ActiveList;
        iLastTouched.lastActiveListType = iLastTouched.Queue.ActiveList==0? iLastTouched.Queue.EnquiryLists[0].Name +"": iLastTouched.Queue.ActiveList + "";
        const results =  await getUnresolvedEnquiriesByQueueGlobal(iLastTouched.Queue.ActiveList)
            const activeQEnquiryList = iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList);
                let EnquiryId = 0;
                if (activeQEnquiryList?.EnquiryId) {
                    EnquiryId = activeQEnquiryList.EnquiryId;
                }
                else if (results.enquiries.length > 0) {
                    EnquiryId = results.enquiries[0].id;
                }

                if(results.enquiries.find( (x:iEnquiry) => x.id == EnquiryId)==undefined){
                    if(results.enquiries.length>0){
                        EnquiryId = results.enquiries[0].id;
                    }else{
                        EnquiryId = 0;
                    }
                }

                if(results.enquiries.find( (x:iEnquiry) => x.id == EnquiryId)==undefined){
                    if(results.enquiries.length>0){
                        EnquiryId = results.enquiries[0].id;
                    }else{
                        EnquiryId = 0;
                    }
                }
                
                if (window.innerWidth > 1066) {
                    results.enquiries.length > 0 ? EnquiryId = EnquiryId : EnquiryId = 0;
                  }
                 
                if (EnquiryId > 0) {
                    store.dispatch(setEmailVisible(true));
                    if (iLastTouched.Queue.ActiveList > 0) {
                        activeQEnquiryList!.EnquiryId = EnquiryId;
                        activeQEnquiryList!.CommId = 0;
                    }
                }
               saveLastTouched(iLastTouched);
                store.dispatch(setActiveEnquiry(EnquiryId));
                store.dispatch(setQueuesVisibility());
                store.dispatch(switchFirstTab("Mailboxes"));
    }
}

export async function getUnresolvedEnquiriesByQueueGlobal(mailboxId: number, id: string = ""): Promise<iEnquiries> {
    setItemEnabled(id, false);
    const LastTouched = getLastTouched();
    var promise = new Promise<any>((resolve, reject) => {
        if (document.getElementById("loader")) {
            (document.getElementById("loader") as HTMLElement)!.style.display = "block";
        }
        let request: iRequest = {
            url: "Enquiries/Queue/" + mailboxId.toString(),
            method: "GET",
            headers: [],
            body: "",
            responseType: "json"
        }
        callApi(request).then((resp) => {
            let enQuiryMap = getEnquiriesMap();
            let ActiveList: number[] = [];
            const lastSort = LastTouched.Queue.EnquiryLists.find(l => l.Name === LastTouched.Queue.ActiveList)?.orderBy ?? { orderBy: null }
            const sortedEnqs = lastSort.orderBy ?
                sortEnquiriesByField(resp.enquiries, lastSort.orderBy, lastSort.desc) :
                sortQueues(resp.enquiries);
            sortedEnqs.map(x=> sortComms(x.communications)); //this sorts the communications history to order by latest on top
            ActiveList = sortedEnqs.flatMap(x=> x.id);
            store.dispatch(updateEnquiriesCache(resp.enquiries));
            saveSessionValue("ActiveListEnquiries",ActiveList);
            setItemEnabled(id, true);
            resolve({"enquiries":sortedEnqs});
        }).catch((err) => {
            reject(err);
        });

    })
    return promise;
}

export function changeQ(item: iQueue) {
    const iLastTouched = getLastTouched();
    iLastTouched.Queue.ActiveList = item.id;
    saveLastTouched(iLastTouched);
  
    if(document.getElementById("SelectedQ")!=undefined){(document.getElementById("SelectedQ") as HTMLElement).innerText = item.name}
    if(document.getElementById("SelectedQCount")!=undefined) {(document.getElementById("SelectedQCount") as HTMLElement).innerText = item.count.toString() };
    try {
        getUnresolvedEnquiriesByQueueGlobal(item.id).then((results)=>{
       
            store.dispatch(setActiveEnquiry(
                iLastTouched.Queue.EnquiryLists.find(x=>x.Name==item.id)?.EnquiryId ??
                results.enquiries[0]?.id ??
                0
            ));

           
        })
       
    }
    catch (err) {
        console.log(err);
    }
}

export async function GetMailboxUsers(mailboxes: number[]): Promise<iUser[]> {
    let mailboxQuery = "";
    for (let mailbox of mailboxes) {
        mailboxQuery += `mailboxId=${mailbox}&`;
    }

    const result: iUser[] = (await Get("Users?" + mailboxQuery +"filterAssignable=true")).users;

    return result.sort((uA, uB) => {
        const aVal = `${uA.firstname} ${uA.lastname}`.toLowerCase();
        const bVal = `${uB.firstname} ${uB.lastname}`.toLowerCase();
        return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
    });
}