import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useAppDispatch, RootState } from '../../../state/store'
import { useSelector } from "react-redux";
 
import { iLastTouched } from "../../../interfaces/lastTouched";
import './Queues.css';
import Enquiry from '../../Enquiries/index';
import { getActiveEnquiries } from '../../../interfaces/enquiryList';
import { iEnquiry } from '../../../interfaces/iEnquiry';
import { setActiveEnquiry } from '../../../state/activeEnquiry';
import { Col, Row } from 'react-bootstrap';
import { SortEnquiriesParams, sortByField, sortEnquiriesByField, sortOwner, sortStatus } from '../../../utilities/sort';
import { ActivateWizardModule } from '../../../Logic/Enquiries/activateWizard';
import { saveActiveListEnquiries } from '../../../utilities/localStore/calls';
import { GlobalModalContext } from '../../../contexts/GlobalModalContext';
import { isDeepStrictEqual } from 'util';
import { getLastTouched, saveLastTouched } from '../../../Logic/LastTouched/misc';
import EnquiryGrid from '../enquiryGrid';
import { EnquiryGridSelectionContext, gridSelectionProperties } from '../../../contexts/enquiry-grid-selection.context';


function QueueLists(props:any) {
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const dispatch = useAppDispatch()
    const [allEnquiries ,setAllenquiries]=React.useState<Array<iEnquiry>>([]);
    const showModal = useContext(GlobalModalContext);
    const LastTouched = getLastTouched();
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);
    const [sortBy, setSortBy] = React.useState<SortEnquiriesParams>();
    const {selectionProperties, SetEnquiryGridSelectionProperties} = useContext(EnquiryGridSelectionContext)

    useEffect(() => {
        const LastTouched = getLastTouched();
        const lastSort = LastTouched.Queue.EnquiryLists.find(l => l.Name === LastTouched.Queue.ActiveList)?.orderBy ?? { orderBy: "dateReceived", desc:true };
        const enqs = getActiveEnquiries().enquiries;
        setAllenquiries(lastSort.orderBy ?
            sortEnquiriesByField(enqs, lastSort?.orderBy, lastSort?.desc) :
            enqs
        );
            if((activeEnquiry.id<1 ) || props.FullWidth==false){
                let lastEnquiryId = LastTouched.Queue.EnquiryLists.find(x => x.Name == LastTouched.Queue.ActiveList)!.EnquiryId == 0 ? 
                    (enqs.length>0? enqs[0].id : 0) :
                    enqs.find(x=> x.id == LastTouched.Queue.EnquiryLists.find(x => x.Name == LastTouched.Queue.ActiveList)!.EnquiryId)!=undefined?
                    LastTouched.Queue.EnquiryLists.find(x => x.Name == LastTouched.Queue.ActiveList)!.EnquiryId : (enqs.length>0? enqs[0].id : 0)
                    if (lastEnquiryId !== undefined) {
                        dispatch(setActiveEnquiry(lastEnquiryId));
                    }
            }
        setSortBy(lastSort);
    }, [enquiriesCache])
    useEffect(() => {
     
        if(selectionProperties.multiSelect  &&  selectionProperties.selectedEnquiries.length==0){
            SetEnquiryGridSelectionProperties(currentState=>{
                currentState = JSON.parse(JSON.stringify(currentState));
                currentState.multiSelect = currentState.multiSelect;
                currentState.selectedEnquiries = [activeEnquiry.id.toString()];
            return currentState;
            }) ;
        }
    }, [selectionProperties.multiSelect]);
       const handleHeaderClick = (fieldName: string) : any => {
        let enSort: SortEnquiriesParams
        const LastTouched = getLastTouched();
        let lastSort = LastTouched.Queue.EnquiryLists.find(l => l.Name == LastTouched.Queue.ActiveList)?.orderBy ?? {orderBy: null};
        const lastOrderBy = lastSort.orderBy

        if (lastOrderBy == fieldName) {
            LastTouched.Queue.EnquiryLists.find(l => l.Name == LastTouched.Queue.ActiveList)!.orderBy = {orderBy: fieldName, desc: !lastSort.desc}
            enSort = {orderBy: fieldName, desc: !lastSort.desc };
        }
        else {
            LastTouched.Queue.EnquiryLists.find(l => l.Name == LastTouched.Queue.ActiveList)!.orderBy = {orderBy: fieldName}
            enSort = {orderBy: fieldName };
        }

        const order = enSort?.orderBy;
        if (order) {
            if (order == "owner") {
                setAllenquiries(es => sortOwner(es, enSort.desc) )
            }
            else if (order == "status") {
                setAllenquiries(es => sortStatus(es, enSort.desc) )
            }
            else {
                setAllenquiries(es => sortByField(es, order, enSort.desc) )
            }

            LastTouched.Queue.EnquiryLists.find(l => l.Name === LastTouched.Queue.ActiveList)!.orderBy = {orderBy: order, desc: enSort.desc}

            const ids = allEnquiries.map(e => e.id)
            saveActiveListEnquiries(ids);
        }
        saveLastTouched(LastTouched);
        setSortBy(enSort);
    }

    const multiSelectOnclick = (selectionProperties:gridSelectionProperties)=>{
        SetEnquiryGridSelectionProperties(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.multiSelect = selectionProperties.multiSelect;
            currentState.selectedEnquiries = selectionProperties.selectedEnquiries;
        return currentState;
        }) ;
    }

    return (
        <>
        {( allEnquiries.length > 0) &&
           <EnquiryGrid data={allEnquiries} theme="dataTable_default" 
                sortFunction={handleHeaderClick}
                sortCol = {sortBy}
                selectedEnquiry={activeEnquiry.id}
                columns={[ "verticalSPacer",
                             "priority",
                            "hasAlarm",
                            "hasUnworkedAttachments",
                            "enquiryReference",
                            "owner",
                            "enquirerName",
                            "dateReceived",
                            "activeSlaCycle.timeout",
                            "status",
                            "subject",
                            "workItems"
                        ]}
                        multiSelect={selectionProperties.multiSelect}
                        selectedItems={selectionProperties.selectedEnquiries}
                        multiSelectOnclick={multiSelectOnclick}
            ></EnquiryGrid> 
            }
        {
            (allEnquiries.length == 0) && <div style={{padding:"12px",fontSize:"9pt"}}>
                No enquiries found
            </div>
        }
    </>
    );
}

export default QueueLists;
