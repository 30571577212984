import { useState } from 'react';
import './styles.scss';
import Dropdown from '../Generic/Form/DropDown';
import { MisEntry } from '../../interfaces/ActionStartResponse';
import { userPreferences } from '../../interfaces/userPrefence';
import { getLocalValue, saveLocalValue } from '../../utilities/localStore/calls';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../state/store';
import { setUserPreferences } from '../../state/userSlice';
 

function General() {
    const UserPreferences = useSelector((state: RootState) => state.UserInformation.preferences);
 
    
    return (<>
        <div className='header'>General</div>
        <div style={{padding:"20px",paddingTop:"13px",fontSize:"12px"}}>

            <div className='row'>
                    <div className='col-sm-12 userPrefViewedHeader' >Enquiry List</div>
            </div><br></br>
            <div className='row'>
       
                    <div className='col-sm-5'>
                    <Dropdown
                MisField={{
                    id: 273283,
                    name: "create-note-priority-dropdown",
                    label: "Multi Select Mode",
                    defaultValue: "1",
                    displayOrder: 0,
                    fieldType: "Dropdown",
                    lookupValues: [
                        { name: "Multi select with preview ", id: 1, order: 1 },
                        { name: "Standard", id: 2, order: 2 },
                    ]
                }}
                MisEntry={{ fieldID: 3, value: UserPreferences.general.enquiryList.multiSelectMode=="multi-preview" ? "1":"2" }}
                returnFunc={(entry: MisEntry) =>
                    {
                       if(entry.value=="1" || entry.value=="2")
                        {
                            let userPreferance = JSON.parse(JSON.stringify(UserPreferences));
                                userPreferance.general.enquiryList.multiSelectMode = entry.value=="1"? "multi-preview" : "multi-standard"
                                saveLocalValue("userPreferances",userPreferance);
                                store.dispatch(setUserPreferences(userPreferance));
                            
                        }
                    }
                
                }
                  
                Valid={true}
                excludeQuickFind={true}
            />
                    </div>
                    <div className='col-sm-7'>
                        <b>Multi select with preview</b><br/>
                        When multi-select mode is active, display a preview of your selected enquiry, exiting selection mode after performing an action.  <br/><br/> 
                        <b>Standard Mode</b><br/>
                        Familiar multi-select interaction like most email clients, remaining in selection mode after performing an action. 
                    </div>
            </div>
        </div>
        </>
    );
}
export default General;