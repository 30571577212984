import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getSessionValue, saveSessionValue } from '../utilities/localStore/calls';
export interface iSiteInfo {
    workflowPoppped: boolean,
    queuesListVisible: boolean,
    enquiriesListVisible: boolean,
    searchResultsVisible: boolean,
    userPreferanceVisible: boolean,
    restrictedView :boolean,
}

const initialState: iSiteInfo = {
    workflowPoppped: false,
    queuesListVisible: false,
    enquiriesListVisible: false,
    restrictedView : false,
    searchResultsVisible:false,
    userPreferanceVisible : false,
}

export const SiteSlice = createSlice({
    name: 'SiteInformation',
    initialState,
    reducers: {
        setSearchResultsVisibility: (state) => {
            state.enquiriesListVisible = false;
            state.queuesListVisible = false;
            state.userPreferanceVisible = false;
            state.searchResultsVisible = true;
        },
        setQueuesVisibility: (state) => {
            state.enquiriesListVisible = false;
            state.queuesListVisible = true;
            state.userPreferanceVisible = false;
            state.searchResultsVisible = false;
        },
        setEnquiriesVisibility: (state) => {
            state.queuesListVisible = false;
            state.enquiriesListVisible = true;
            state.userPreferanceVisible = false;
            state.searchResultsVisible = false;
        },
        setUserPreferancesVisibility: (state) => {
            state.queuesListVisible = false;
            state.enquiriesListVisible = false;
            state.userPreferanceVisible = true;
            state.searchResultsVisible = false;
        },
        setRestrictedView: (state,action) => {
            state.restrictedView = action.payload;
            if(action.payload==true){
                if(!getSessionValue<boolean>("ScreenSizeChanged")){
                    saveSessionValue("ScreenSizeChanged",false)
                }else{
                    saveSessionValue("ScreenSizeChanged",true);
                }
            }
           
        }

    }
})


// Action creators are generated for each case reducer function
export const {  setQueuesVisibility, setEnquiriesVisibility, setUserPreferancesVisibility,   setRestrictedView, setSearchResultsVisibility } = SiteSlice.actions

export default SiteSlice