import {
    BsListNested,
    BsMagic,
    BsPeople,
    BsPersonAdd,
    BsSticky,
    BsTrash3,
    BsCardChecklist
} from "react-icons/bs"
import { IconMenuItems } from "."
import { ActivateWizardModule } from "../../Logic/Enquiries/activateWizard"
import { Dispatch, useContext, useEffect, useState } from "react"
import { CreateNoteContext } from "../../contexts/create-note.context"
import { EnquiryMenuProps } from "../../interfaces/menu"
import { ChangeEnquiryOwnershipContext } from "../../contexts/changeEnquiryOwnership.context"
import { RemoveEnquiriesContext } from "../../contexts/removeEnquiries.context"
import { ChangeEnquiryPriorityContext } from "../../contexts/changeEnquiryPriority.context"
import { PriorityIcon } from "../../assets/svg/priority.icon"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../state/store"
import { getEnquiryById, refreshList } from "../../interfaces/enquiryList"
import { GetMailboxPermissions, Permissions as iPermissions } from "../../interfaces/Permissions"
import { iEnquiry } from "../../interfaces/iEnquiry"
import { setActiveEnquiry } from "../../state/activeEnquiry"
import Statuses from "../../interfaces/Statuses"
import { ReplaceEnquiryOwnershipContext } from "../../contexts/replaceEnquiryOwnership.context"
import { EnquiryPropertiesContext } from "../../contexts/enquiry-properties.context"
import { EnquiryGridSelectionContext } from "../../contexts/enquiry-grid-selection.context"
import { getEnquiries } from "../../utilities/localStore/calls"
import { TakeOwnership } from "../../Logic/EmsAPI/Enquiries"

interface  EnquiryMenuPropswithHide extends EnquiryMenuProps{
    onHide:()=> void
}
export function EnquiryMenuItems({ enquiryIds, menuStyle, onHide }: EnquiryMenuPropswithHide) {

    const createNote = useContext(CreateNoteContext)
    const changeEnquiryOwnership = useContext(ChangeEnquiryOwnershipContext)
    const removeEnquiries = useContext(RemoveEnquiriesContext)
    const changeEnquiryPriority = useContext(ChangeEnquiryPriorityContext)
    const replaceEnquiryOwnership = useContext(ReplaceEnquiryOwnershipContext)
    const enquiryProperties = useContext(EnquiryPropertiesContext);
    const permissions = useSelector((state: RootState) => state.permissions);
    const User = useSelector((state: RootState) => state.UserInformation);
    const dispatch: Dispatch<any> = useAppDispatch();
    const {selectionProperties, SetEnquiryGridSelectionProperties} = useContext(EnquiryGridSelectionContext)
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);
    const enquiries = enquiriesCache.filter(x=> enquiryIds.includes(x.id));
 
    function canTakeOwnership(): boolean {
        
        if (!enquiries || enquiries.length == 0)
            return false;
         
        if (enquiries.find(o=> o.owners?.find(o => o.id === User.userID)))
            return false;

        for (const enquiry of enquiries) {
            const mailboxPermissions = GetMailboxPermissions(permissions, enquiry.mailbox)

            const hasReplace = mailboxPermissions.ReplaceEnquiryOwnership;
            const hasTake = mailboxPermissions.TakeEnquiryOwnership;
            const hasOwners = enquiry.owners.length > 0;

            if (hasOwners && !hasReplace)
                return false;

            if (!hasTake && !hasReplace)
                return false;
        };

        return true;
    }

    function EnquiriesHasOwners():boolean {
        return enquiries.find(x=> x.owners.length > 0)? true:false;   
    }
 
    
    function EnquiriesInProcessing():boolean{
        return enquiries.find(x=> x.status === Statuses.Processing.ID)? true : false;
    }
    return (
        enquiries != undefined && enquiries.length == 0  ? <></>:<IconMenuItems options={[
             {
                icon: <i><BsMagic /></i>,
                label: "Activate Wizard",
                onClick: () => new ActivateWizardModule().ActivateWizard(enquiryIds[0]),
                disabled: !(new ActivateWizardModule().wizardIdPermitted(enquiryIds[0]))|| selectionProperties.multiSelect
            },
          
            {
                icon: <i><BsSticky /></i>,
                label: "Create Note",
                onClick: () => {
                    createNote(enquiries[0].id)
                    onHide();
                },
                disabled: !enquiries || enquiries.length !== 1 || selectionProperties.multiSelect
            },
           {
                icon: <i><BsPersonAdd /></i>,
                label: "Take Ownership",
                onClick: () => {
                   enquiries.find(op => op.owners?.length === 0) ?
                        TakeOwnership(enquiryIds).then(() => {
                            refreshList(User.userID).then(() => {
                                dispatch(setActiveEnquiry(0));
                                if(selectionProperties.multiSelect){
                                    if(User.preferences.general.enquiryList.multiSelectMode=="multi-preview"){
                                        SetEnquiryGridSelectionProperties(currentState=>{
                                            currentState = JSON.parse(JSON.stringify(currentState));
                                            currentState.multiSelect = false;
                                            currentState.selectedEnquiries = [];
                                        return currentState;
                                        }) ;
                                    }
                                }
                            });
                        }) : replaceEnquiryOwnership(enquiries);
                    onHide();
                },
                disabled: !canTakeOwnership() || EnquiriesInProcessing()
            },
           {
                icon: <i><BsPeople /></i>,
                label: "Change Enquiry Ownership",
                onClick: () => {
                    changeEnquiryOwnership(enquiries)
                    onHide()
                },
                disabled: enquiries.find(x=> 
                    !GetMailboxPermissions(permissions, x.mailbox).ModifyEnquiryOwnership || x.status == Statuses.Processing.ID
                ) ?   true:false
            },
          {
                icon: <i>
                    <PriorityIcon
                        color={enquiries.find(x=> !GetMailboxPermissions(permissions, x.mailbox).ModifyEnquiryPriority || x.status !== Statuses.Received.ID) ? "#E6E7E8" : "black"}
                    />
                </i>,
                label: "Change Enquiry Priority",
                onClick: () => {
                    changeEnquiryPriority(enquiries)
                    onHide()
                },
                disabled: enquiries.find(x=> 
                    !GetMailboxPermissions(permissions, x.mailbox).ModifyEnquiryPriority || x.status !== Statuses.Received.ID) 
                    ? true: false
            },
             { 
                icon: <i><BsTrash3/></i>,
                label: "Remove Enquiry",
                onClick: () => {
                    removeEnquiries(enquiries)
                    onHide()
                },
                hidden: enquiries.find(x=> 
                    !GetMailboxPermissions(permissions, x.mailbox).CloseEnquiry) 
                    ? true: false,
                disabled: 
                    enquiries.find(x=> x.status!=Statuses.Received.ID)   || 
                    enquiries.find(x=> x.owners.length>0 && !(x.owners.length===1 && x.owners[0].id === User.userID))
                    ? true : false
            },  
            {
                icon: <i><BsCardChecklist /></i>,
                label: "Enquiry Properties",
                onClick: () => {
                    enquiryProperties(enquiries[0].id);
                    onHide();
                },
                disabled: !enquiries || enquiries.length !== 1 || selectionProperties.multiSelect
            }
                
        ]}
        menuStyle={menuStyle ?? "context-menu"}
        />
    )
}
