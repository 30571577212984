import { getLocalValue, saveLocalValue } from "../utilities/localStore/calls"


export interface iPreference{
    
    interface:{
        MailboxMenuVisible:boolean,
        MyEnquiriesQueueStatusVisible:boolean,
        LeftPane:number,
        RightPane:number
    }
}



export const getPreference = ():iPreference =>{

    let preference =  getLocalValue<iPreference>("preference") ;
    if(!preference){
        preference = {
                interface: {
                    MailboxMenuVisible:true,
                    MyEnquiriesQueueStatusVisible:false,
                    LeftPane:50,
                    RightPane:50
                }
            }
            savePrefence(preference);
    }

    return preference ;

}

export const savePrefence = (data: iPreference) =>{

    saveLocalValue("preference",data);
}