import worker_script from '../workerProcesses/keepAlive'
import { iWizardWorker } from '../interfaces/workers';
import { changeConnection } from '../state/connection';
import store from '../state/store'
import { getSessionValue, removeSessionValue, saveSessionValue } from './localStore/calls';
import { deactivateEnquiryWizard } from '../interfaces/enquiryList';
import { getTenantToken } from '../Logic/Auth/TokenManager';
import { callApi, iRequest } from './apiCall';
import { removeTab } from '../state/tabRow';
import { Wizard } from '../state/workflowSlice';
export async function keepTheConnectionAlive(divId: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            if (window.Workers.find(x => x.enquiryId == divId) == undefined && getSpecificWorkerFromSession(divId) == undefined) {
                let request: iRequest = {
                    url: "Enquiry/" + divId + "/Action",
                    method: "GET",
                    headers: [],
                    body: "",
                    responseType: "json"
                }
                const misResponse = await callApi(request);
                const code = worker_script.toString();
                const blob = new Blob([`(${code})()`]);
                const worker: Worker = new Worker(URL.createObjectURL(blob));
                let sURL = process.env.REACT_APP_API;
                if (!sURL || sURL.length === 0) {
                    var str = window.location;
                    let stringtoReplaces =  
                           str.origin.substring(str.protocol.length+2, str.origin.indexOf(".", str.protocol.length+2))
                    sURL =  str.origin.replace(stringtoReplaces,"api");
                }
                if (!sURL.endsWith("/")) {
                    sURL += "/";
                }

                let data = { "token": getTenantToken(), enquiryId: divId, APIUrl:sURL };
                worker.postMessage(data);
                worker.onmessage = event => {
                    if (event.data.error == true) {
                        if (event.data.data == "connection lost") {
                            store.dispatch(changeConnection(false));
                            window.Workers = window.Workers.filter(x => x.enquiryId != divId);
                            deleteWorkerProcessFromSession(divId);
                            worker.terminate();
                            reject("connection lost");
                        }
                        else if (event.data.data == "unable to connect") {
                            window.Workers = window.Workers.filter(x => x.enquiryId != divId);
                            deleteWorkerProcessFromSession(divId);
                            worker.terminate();
                            reject("unable to connect");
                        }
                        else if (event.data.data == "cannot action the wizard") {
                            window.Workers = window.Workers.filter(x => x.enquiryId != divId);
                            deleteWorkerProcessFromSession(divId);
                            worker.terminate();
                            reject("cannot action the wizard");
                        }
                    }
                    else if (event.data.error == false) {
                        if (Object.keys(misResponse).length >= 1) {
                            window.Workers.push({ "enquiryId": divId, worker: worker });
                            addWorkerProcessToSession(divId);
                            resolve(misResponse);
                        } else {
                            window.Workers = window.Workers.filter(x => x.enquiryId != divId);
                            deleteWorkerProcessFromSession(divId);
                            worker.terminate();
                            reject("NO Mis Model");
                        }
                    }
                }

            }
            else {
                resolve("success");
            }
        }
        catch (err) {
            reject(err);
        }
    });

}

export async function closeAllWizards(): Promise<void> {
    const wizards = getSessionValue<Wizard[]>("Workflows") ?? [];
    for (const wizard of wizards) {
        await closeConnection(wizard.EnquiryId);
        store.dispatch(removeTab(wizard.EnquiryId))
    }
}

export async function closeConnection(divId: number): Promise<any> {
    let _worker: iWizardWorker = window.Workers.find(x => x.enquiryId == divId)!;
    if (_worker) {
        _worker.worker.terminate();
        window.Workers = window.Workers.filter(x => x.enquiryId != divId);
    };

    deleteWorkerProcessFromSession(divId);
    await deactivateEnquiryWizard(divId);

    return "success";
};

export const addWorkerProcessToSession = (enquiryId: number) => {
    let allWorkers: number[] = [];
    const workers = getSessionValue<number[]>("workerProcesses");
    if (workers !== null) {
        allWorkers = workers.filter(x => x != enquiryId);
    }
    allWorkers.push(enquiryId);
    saveSessionValue("workerProcesses", allWorkers);
}

export const getWorkerProcessesFromSession = (): Array<number> => {
    return getSessionValue<number[]>("workerProcesses") ?? [];
}

export const getSpecificWorkerFromSession = (enquiryId: number): number | undefined => {
    return getSessionValue<number[]>("workerProcesses")
        ?.find(w => w === enquiryId);
}

export const deleteWorkerProcessFromSession = (enquiryId: number) => {
    let allWorkers: number[] = [];
    const workers = getSessionValue<number[]>("workerProcesses");
    if (workers !== null) {
        allWorkers = workers.filter(x => x != enquiryId);
    }
    if (allWorkers.length === 0)
        removeSessionValue("workerProcesses");
    else
        saveSessionValue("workerProcesses", allWorkers);
}


export const recreateWorkersFromSession = async (enquiryId: number | null): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        const allWorkers = getSessionValue<number[]>("workerProcesses")
        if (allWorkers != null) {
            if (enquiryId == null) {
                for (let i = 0; i < allWorkers.length; i++) {
                    await keepTheConnectionAlive(allWorkers[i]);
                }
                resolve("success");
            } else {
                let allWorker = allWorkers.filter(x => x == enquiryId)[0];
                if (allWorker > 0) {
                    await keepTheConnectionAlive(allWorker);
                    resolve("success");
                }

            }
        }
        else {
            reject("no workers");
        }
    })

}