import './index.scss'
import { useEffect, useState } from "react";
import Confirm from "../Generic/ConfirmDialog";
import PropertiesTab from "./PropertiesTab";
import General from "./General";
import DatesAndTimes from "./DatesAndTimes";
import { GetEnquiryDetails, iEnquiryDetails, UpdateEnquiryDetails } from '../../Logic/EmsAPI/Enquiries';
import { ActiveTabName } from "./ActiveTabName"
import { GetMailboxPermissions } from "../../interfaces/Permissions";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { BsXLg } from "react-icons/bs";
import Statuses from "../../interfaces/Statuses";
import ConfirmDialog from "../Generic/ConfirmDialog";

interface Props {
    enquiryId: number,
    onCancel: () => void,
    onCreated: () => void,
    onError: () => void
}

export interface iEnquiryPropertiesProps {
    enquiryDetails?: iEnquiryDetails,
    handleChange: <K extends keyof iEnquiryDetails>(newValue: iEnquiryDetails[K], key: K) => void,
    updateRestricted: boolean
}

export function EnquiryPropertiesModal(props: Props) {

    const [confirmCancel, setConfirmCancel] = useState(false);
    const [activeTab, setActiveTab] = useState<ActiveTabName>(ActiveTabName.general);
    const [enquiryDetails, setGeneral] = useState<iEnquiryDetails>();
    const permissions = useSelector((state: RootState) => state.permissions);
    const updateRestricted = !enquiryDetails || !GetMailboxPermissions(permissions, enquiryDetails!.mailbox).ModifyEnquiryProperties || enquiryIsProcessing();
    const [isUserProcessing, setIsUserProcessing] = useState(false);
    const [Dirty, setDirty] = useState(false);
    
    function enquiryIsProcessing():boolean{
        return enquiryDetails?.status === Statuses.Processing.ID;
    }
    useEffect(() => {
        getEnquiryDetail(props.enquiryId)
       
    }, [props.enquiryId]);


    const handleChange = <K extends keyof iEnquiryDetails>(newValue: iEnquiryDetails[K], key: K) => {
        setGeneral((g) => ({ ...g!, [key]: newValue }));
        setDirty(true);
    }

    async function UpdateFields() {
        if (!Dirty)
            props.onCancel();
        else if (enquiryDetails) {
            const response = await UpdateEnquiryDetails(enquiryDetails!);
            
            if (!response.isOk) {
                if (response.status == 400 && response.validationErrors?.errors?.EnquiryID?.find(errorMessage=>errorMessage=="Enquiry is in processing."))
                {
                    setIsUserProcessing(true);
                }
                else
                    throw new Error(`${response.status} returned from server.`);
            }
            else{
                props.onCreated();
            }    
        }
    }
  
        
    function getEnquiryDetail(enquiryId: number) {
        GetEnquiryDetails(enquiryId).then((response) => {
            if (!response.isOk) {
                throw new Error(`${response.status} returned from server.`);
            }
            if (response.data) {
                setGeneral(response.data);
            }
        })
    }

    return (<>
        <div className="enquiry-properties">
            <div className="enquiry-properties-content">
                <div className="enquiry-properties-tabs-content">
                    <div className="tabs-header">Enquiry Properties</div>
                    <PropertiesTab
                        title={ActiveTabName.general}
                        onClick={() => setActiveTab(ActiveTabName.general)}
                        active={activeTab === ActiveTabName.general}
                    />
                    <PropertiesTab
                        title={ActiveTabName.datesTimes}
                        onClick={() => setActiveTab(ActiveTabName.datesTimes)}
                        active={activeTab === ActiveTabName.datesTimes}
                    />
                    <PropertiesTab
                        title={ActiveTabName.owners}
                        onClick={() => setActiveTab(ActiveTabName.owners)}
                        active={activeTab === ActiveTabName.owners}
                        disabled
                    />
                    <PropertiesTab
                        title={ActiveTabName.alarms}
                        onClick={() => setActiveTab(ActiveTabName.alarms)}
                        active={activeTab === ActiveTabName.alarms}
                        disabled
                    />
                    <PropertiesTab
                        title={ActiveTabName.history}
                        onClick={() => setActiveTab(ActiveTabName.history)}
                        active={activeTab === ActiveTabName.history}
                        disabled
                    />
                    <PropertiesTab
                        title={ActiveTabName.production}
                        onClick={() => setActiveTab(ActiveTabName.production)}
                        active={activeTab === ActiveTabName.production}
                        disabled
                    />
                    <PropertiesTab
                        title={ActiveTabName.userCycle}
                        onClick={() => setActiveTab(ActiveTabName.userCycle)}
                        active={activeTab === ActiveTabName.userCycle}
                        disabled
                    />
                    <PropertiesTab
                        title={ActiveTabName.response}
                        onClick={() => setActiveTab(ActiveTabName.response)}
                        active={activeTab === ActiveTabName.response}
                        disabled
                    />
                    <PropertiesTab
                        title={ActiveTabName.mis}
                        onClick={() => setActiveTab(ActiveTabName.mis)}
                        active={activeTab === ActiveTabName.mis}
                        disabled
                    />
                </div>
                <div className="enquiry-properties-main-content">
                    <section className="header-section">
                        <div className="header">
                            {activeTab}
                        </div>
                        <i
                            className='x'
                            onClick={() => setConfirmCancel(true)}
                        >
                            <BsXLg className='close-bttn' />
                        </i>
                    </section>
                    <section className="body">
                        <div className="confirm-message-body">
                            <div style={{ width: "100%" }}>
                                {activeTab === ActiveTabName.general && <General
                                    enquiryDetails={enquiryDetails}
                                    handleChange={handleChange}
                                    updateRestricted={updateRestricted}
                                />}
                                {activeTab === ActiveTabName.datesTimes && <DatesAndTimes
                                    enquiryDetails={enquiryDetails}
                                    handleChange={handleChange}
                                    updateRestricted={updateRestricted}
                                />}
                            </div>
                        </div>
                    </section>
                    <section className="buttons-section">
                        <div className="buttons-wrapper">
                            <button 
                                key="dialog-confirm"
                                className= {"g-btn g-btn-action"}
                                onClick={UpdateFields}
                            >Ok</button>
                            <button key="dialog-cancel" className={"g-btn g-btn-alternate"} onClick={() => {if(!Dirty) props.onCancel(); else setConfirmCancel(true);
                            }}>Cancel</button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        {confirmCancel &&
            <Confirm
                onCancel={() => setConfirmCancel(false)}
                title="Warning"
                onConfirm={props.onCancel}
            >
                <>Unsaved changes will be lost. Are you sure that you wish to close?</>
            </Confirm>}
            {
            isUserProcessing && Dirty &&
            <ConfirmDialog
                title="Warning"
                onConfirm={() => { setIsUserProcessing(false) }}
            >
                <>
                This enquiry is currently being worked by another user. <br />
                Your changes could not be saved.
                </>
            </ConfirmDialog>
        }
    </>)
}