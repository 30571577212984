import * as React from 'react';
import DataTable, { dataColumn } from '../Generic/DataTable';
import { iEnquiry } from '../../interfaces/iEnquiry';
import { ActivateWizardModule } from '../../Logic/Enquiries/activateWizard';
import { useContextMenu } from '../../hooks/useContextMenu';
import Statuses from '../../interfaces/Statuses';
import { sortByField, SortEnquiriesParams, sortOwner, sortStatus } from '../../utilities/sort';
import { useEffect, useState } from 'react';
import { saveSessionValue } from '../../utilities/localStore/calls';
import { GlobalModalContext } from '../../contexts/GlobalModalContext';
import { BsListCheck, BsPaperclip, BsThermometerHalf } from 'react-icons/bs';
import { Bell } from 'react-feather';
import { dateTimeFormat222 } from './enquiry/enquiry';
import { EnquiryBurgerMenu, EnquiryContextMenu } from '../Menu';
import { getLastTouched, saveLastTouched } from '../../Logic/LastTouched/misc';
import { gridSelectionProperties } from '../../contexts/enquiry-grid-selection.context';
import { generalPreferances } from '../../interfaces/userPrefence';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import Confirm from "../Generic/ConfirmDialog";

export interface gridProps {
    data:iEnquiry[],
    theme: string,
    selectedEnquiry:  number,
    columns: string[],
    sortFunction: (column:string)=>{},
    sortCol:SortEnquiriesParams| undefined,
    multiSelect: boolean,
    selectedItems: string[],
    multiSelectOnclick?: ( selectedItems: gridSelectionProperties)=>void
}
function EnquiryGrid(props: gridProps) {
    const UserPreferences = useSelector((state: RootState) => state.UserInformation.preferences);
    const [enquiriesInUserProcessing, setEnquiriesInUserProcessing] = useState(false);
    let AllColumns:dataColumn<iEnquiry>[] =[
        {
            title: <>&nbsp;</>,
            key:"verticalSPacer",
            getData: (data:iEnquiry) => { 
              
                switch (props.multiSelect){
              
                    default
                      :{
                            return <div className="verticalBorder">
                                <div style={{ height: "40px",  marginTop: "-20px", marginBottom: "-40px", float: "left", marginLeft: "-6px" }} >
                                    <div style={{margin:"7px 3px 0px 5px",}}>
                                        <input type="checkbox" style={{width:"12px"}} 
                                            id={"ch_"+data.id}
                                            value={data.id}
                                            className='g-Checkbox-default'
                                            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                                e.stopPropagation();
                                                onCheckboxClick(e);
                                                }
                                            }
                                            checked={ props.selectedItems.findIndex(x=> x==data.id.toString())<0? false:true}
                                    ></input></div>
                                </div>
                        </div>
                        break;
                    }
                    case false:{
                        return <div className="verticalBorder">
                                <div style={{ height: "40px", width: "8px", marginTop: "-20px", marginBottom: "-40px", float: "left", marginLeft: "-6px" }} >&nbsp;</div>
                            </div>
                        break;
                    }    
                }

            },
            width: "20px",
            canResize: false,
            sort: undefined,
            textAlignment: "left",
            minWidth: "10px"
        },
        {
            title: <BsThermometerHalf></BsThermometerHalf>,
            toolTip:"Priority",
            key:"priority",
            getData: (data:iEnquiry) => {
                return <div className="sla-priority-bar" style={{marginLeft:"2px"}}>
                    <div style={{ height: ((255 - data.priority) / 255.0) * 100.0 + "%" }} className="sla-priority--value">&nbsp;</div>
                </div>
            },
            width: "20px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("priority") },
                displayIcon: false
            },
            textAlignment: "left",
            minWidth: "15px"
        },
        {
            title: <Bell size={12}></Bell>,
            key:"hasAlarm",
            toolTip:"Alarms",
            getData: (data:iEnquiry) => { return data.hasAlarm == true ? <Bell size={12}></Bell> : "" },
            width: "18px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("hasAlarm") },
                displayIcon: false
            },
            textAlignment: "left",
            minWidth: "15px"
        },
        {
            title: <BsPaperclip></BsPaperclip>,
            key:"hasUnworkedAttachments",
            toolTip:"Unworked Attachments",
            getData: (data:iEnquiry) => { return data.hasUnworkedAttachments === true ? <BsPaperclip></BsPaperclip> : "" },
            width: "20px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("hasUnworkedAttachments") },
                displayIcon: false
            },
            textAlignment: "left",
            minWidth: "10px"
        },

        {
            title: <>Reference</>,
            key:"enquiryReference",
            toolTip:"Reference",
            getData: (data:iEnquiry) => {
                return <span className="enquiryReference">{data.enquiryReference}</span>
            },
            width: "130px",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("enquiryReference") },
                displayIcon: true
            },
            textAlignment: "left",
           minWidth: "90px"
        },
        {
            title: <>Owner</>,
            key:"owner",
            toolTip:"Owner",
            getData: (data:iEnquiry) => { return data.owners.map((item) => { return `${item.lastname}, ${item.firstname}` }).join('; ') },
            width: "20%",
            
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("owner") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
        {
            title: <>Enquirer</>,
            key:"enquirerName",
            toolTip:"Enquirer",
            getData: (data:iEnquiry) => { return data.enquirerName },
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("enquirerName") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        }, 
   
        {
            title: <>Received</>,
            key:"dateReceived",
            toolTip:"Received",
            getData: (data:iEnquiry) => { return dateTimeFormat222(data.dateReceived )},
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("dateReceived") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
        {
            title: <>Expiry</>,
            key:"activeSlaCycle.timeout",
            toolTip:"Expiry",
            getData: (data:iEnquiry) => { return dateTimeFormat222(data.activeSlaCycle?.timeout? data.activeSlaCycle?.timeout :"")},
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("activeSlaCycle.timeout") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        }, 
   
        {
            title: <>Status</>,
            key:"status",
            toolTip:"Status",
            getData: (data:iEnquiry) => { return Statuses.Name(data.status) },
            width: "35%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("status") },
                displayIcon: true
            },
            textAlignment: "left",
          minWidth: "80px"
        },
        {
            title: <>Subject</>,
            key:"subject",
            toolTip:"Subject",
            getData: (data:iEnquiry) => { return data.subject},
            width: "35%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("subject") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
       
        {
            title: <>Completed</>,
            key:"dateCompleted",
            toolTip:"Completed",
            getData: (data:iEnquiry) => { return  data.dateCompleted?  dateTimeFormat222(data.dateCompleted):""},
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("activeSlaCycle.timeout") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
        {
            title: <>Items</>,
            key:"workItems",
            toolTip:"Items",
            getData: (data:iEnquiry) => { return data.workItems },
            width: "50px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("workItems") },
                displayIcon: false
            },
            textAlignment: "right",
            minWidth: "50px",
            maxWidth: "50px"
        },
    ]
 
    AllColumns = AllColumns.filter(col => props.columns.find(propCol => propCol === col.key));
    for (let col of AllColumns) {
        if(props.sortCol && props.sortCol.orderBy == col.key)    {
            if(col.sort){
                col.sort.direction = props.sortCol?.desc ? "desc" : "asc"
            }
        }
    }

    const showModal = React.useContext(GlobalModalContext);
    const { contextMenuState, setContextMenuState, closeContextMenu } = useContextMenu();
    const showDataTableContextMenu = (keys: string[], x: number, y: number) => {
        if(props.multiSelect && props.data.filter(x=> (keys.includes(x.id.toString()))).filter(z=> z.status == Statuses.Processing.ID).length==0 ){
            closeContextMenu();
            setContextMenuState({ keys: keys.map(x=>(parseInt(x))), visible: true, x: x, y: y, })
        } else if(!props.multiSelect){
            closeContextMenu();
            setContextMenuState({ keys: keys.map(x=>(parseInt(x))), visible: true, x: x, y: y })
        }
        else{
            setEnquiriesInUserProcessing(true)
        }
    }

    const onCheckboxClick = (checkboxClick: any) => {
       let sData = [...props.selectedItems];
        if(checkboxClick.currentTarget.checked){
            if (checkboxClick.shiftKey) {
                if (props.selectedItems.length > 0) {
                    let lastEnq = props.selectedItems[props.selectedItems.length - 1];
                    let startIndex = 0, endIndex = 0;
                    startIndex = props.data.findIndex(x=> x.id.toString()==lastEnq);
                    endIndex = props.data.findIndex(x=> x.id==parseInt(checkboxClick.currentTarget.value));
                    for(var i=startIndex;i<(endIndex+1); i++){
                        sData.push(props.data[i].id.toString());
                    }
                    sData = [...new Set(sData)]; // this removes any duplicates
                    props.multiSelectOnclick!({multiSelect:props.multiSelect,selectedEnquiries:sData});
                }
                else {
                    props.multiSelectOnclick!({multiSelect:props.multiSelect,selectedEnquiries:sData.concat((checkboxClick.currentTarget.value))});
                }
            } else{
                props.multiSelectOnclick!({multiSelect:props.multiSelect,selectedEnquiries:sData.concat((checkboxClick.currentTarget.value))});
            }
        }
        else{
            props.multiSelectOnclick!({multiSelect:props.multiSelect,selectedEnquiries:props.selectedItems.filter(x=> x!=(checkboxClick.currentTarget.value))});
        }
    }

    const keydownHandle = (event: React.KeyboardEvent<HTMLTableRowElement>) => {
        if (event == null)
            return;
        if (event.key != "ArrowUp" && event.key != "ArrowDown")
            return;
        const activeEnquiryIndex = props.data.findIndex(x => x.id.toString() === (props.selectedItems.length>0 ? 
            props.selectedItems[props.selectedItems.length-1] :
                                                                        props.selectedEnquiry.toString()));

        let nextEnquiry = 0;
                let nextEnquiryIndex;
                if (event.key == "ArrowUp") {
                    nextEnquiryIndex = activeEnquiryIndex > 0 ? activeEnquiryIndex - 1 : activeEnquiryIndex;
                }
                else {
                    nextEnquiryIndex = activeEnquiryIndex < props.data.length - 1 ? activeEnquiryIndex + 1 : activeEnquiryIndex;
                }
                nextEnquiry = props.data[nextEnquiryIndex].id;

        if(props.multiSelect==false)
            {
                if (props.selectedEnquiry != nextEnquiry) {
                        const enqModule = new ActivateWizardModule();
                        enqModule.click = 0;
                        enqModule.showEnquiry(nextEnquiry);
                }
            }
        else  {
            if(event.shiftKey==true){
                if(props.selectedItems.length>0){
                    let sData = [...props.selectedItems]
                    sData.push(nextEnquiry.toString());
                    sData = [...new Set(sData)];
                    props.multiSelectOnclick!({multiSelect:props.multiSelect,selectedEnquiries:sData});
                }
            }
        }
    }

    const customClassforGrid = (e:iEnquiry) :string => {
        let classes =  getExpiryColour(e) ;
        classes += (!Statuses.IsCompleted(e.status)) && e.hasNew ? " has-new" : "";
        return classes;
    };
    const getExpiryColour = (e:iEnquiry):string => {
        let multiSelectClasses = "";
            if(props.multiSelect){
                if(props.selectedItems.find(x=> x==e.id.toString())!=undefined){
                    multiSelectClasses = "  multiSelect "
                }
                else if(e.id==props.selectedEnquiry){
                    multiSelectClasses= " multiSelectActiveEnquiry ";
                }
            }
        if (e.activeSlaCycle != null && !Statuses.IsCompleted(e.status)) {
            let reminderDate = e.activeSlaCycle.reminder? new Date( e.activeSlaCycle?.reminder) : "";
            let alarmDate = e.activeSlaCycle.alarm ? new Date(e.activeSlaCycle?.alarm) : "";
            let timeoutDate = e.activeSlaCycle.timeout ? new Date(e.activeSlaCycle?.timeout) : "";
            const currentDate = new Date();
            
            if (currentDate < reminderDate) {
                return  " expiry-green "  + multiSelectClasses;
            }
            else if (currentDate >= reminderDate && currentDate < alarmDate) {
                return  " expiry-orange "    + multiSelectClasses;
            }
            else if (currentDate >= alarmDate && currentDate < timeoutDate) {
                return  " expiry-red "   + multiSelectClasses;
            }
            else if (currentDate > reminderDate) {
                return  " expiry-purple "   + multiSelectClasses;
            }
            else{
                return "";
            }
        } else {
            return " expiry-none "+ multiSelectClasses;
        }
    }

     return (
        <>
        {AllColumns.length>0 ?  
<>
<div style={{float:"right",marginTop:"-40px"}}>
<EnquiryBurgerMenu
        key={"burger-menu-dropdown"} 
        enquiryIds={ props.multiSelect? props.selectedItems.map(x=>{return parseInt(x)}) : [props.selectedEnquiry]}
    ></EnquiryBurgerMenu>
   
   <div  className={props.multiSelect == false? "multiSelectIcon": "multiSelectIcon-selected"} title={props.multiSelect == false? "Select Multiple" : "De-select Multiple"}>
       <BsListCheck onClick={()=>{
        props.multiSelectOnclick!({multiSelect:props.multiSelect== false? true: false,
                            selectedEnquiries:props.multiSelect== false? props.selectedItems:  []});
       
        }}></BsListCheck> 
    </div>
    {props.multiSelect  ? <div style={{float:"right", fontSize:"9pt", margin:"1px 5px"}}>{props.selectedItems.length} selected of {props.data.length} </div> :""}
 </div>

        <DataTable data={props.data} theme="dataTable_default"
                    columns={AllColumns} uniqueIdentifiyer={(x)=>{return x.id.toString()}} 
                    onRowClick={(x: string[], clickEVT) =>{
                        if(props.multiSelect==false){
                            if(clickEVT.ctrlKey==true || clickEVT.shiftKey==true){
                                x = [...new Set(x)];
                                props.multiSelectOnclick!({multiSelect:true,selectedEnquiries:x});
                            }else
                            {
                                const enqM = new ActivateWizardModule();
                                enqM.showEnquiry(parseInt(x[0]));
                            }
                        }else
                        {
                            switch(UserPreferences.general.enquiryList.multiSelectMode){
                                case  "multi-preview"  :
                                    if(clickEVT.ctrlKey==true || clickEVT.shiftKey==true){
                                        x = [...new Set(x)];
                                        props.multiSelectOnclick!({multiSelect:props.multiSelect,selectedEnquiries:x});
                                    }
                                    else{
                                        const enqM = new ActivateWizardModule();
                                        enqM.showEnquiry(parseInt(x[x.length-1]));
                                    }
                                break
                                case  "multi-standard"  :
                                    if(clickEVT.ctrlKey==true || clickEVT.shiftKey==true){
                                    x = [...new Set(x)];
                                    props.multiSelectOnclick!({multiSelect:props.multiSelect,selectedEnquiries:x});
                                }
                                else{
                                    const enqM = new ActivateWizardModule();
                                    enqM.showEnquiry(parseInt(x[x.length-1]));
                                    props.multiSelectOnclick!({multiSelect:false,selectedEnquiries:[]});
                                }
                                break
                            }
                        }
                    }
                    } 
                    onRowDblClick={(x: string[]) =>{
                        if (x.length == 1) {
                            const enqM = new ActivateWizardModule();
                            enqM.ActivateWizard(parseInt(x[0]), () => showModal({ type: "EnquiryLocked" }));
                        }
                    }}
                    selectedItem={props.selectedEnquiry.toString()}
                    selectedItems={props.selectedItems}
                    contextMenu={showDataTableContextMenu} keyPress={keydownHandle}
                    spacerRow={3}  
                    getCustomClass={(e)=>{
                        let classes ="";

                            classes =  getExpiryColour(e) ;
                            classes += (!Statuses.IsCompleted(e.status)) && e.hasNew ? " has-new" : "";
                      
                        return classes;
                    }}
                ></DataTable> </>: <></>
    }
    {(contextMenuState.visible) && (
                <EnquiryContextMenu
                    x={contextMenuState.x}
                    y={contextMenuState.y}
                    enquiryIds={contextMenuState.keys}
                    onHide={closeContextMenu}
                />
            )}
            {(enquiriesInUserProcessing)&&(
                <Confirm
                title='Warning'
                onConfirm={() => {
                    setEnquiriesInUserProcessing(false)
                }}
                
            >
                <p>One or many enquiries you selected is currently in User Processing. Please deselect it to perform your action.</p>

                </Confirm>
            )}
 </>      
    )
};

export default EnquiryGrid;

 