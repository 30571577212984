function keepAlive()
{
    // eslint-disable-next-line no-restricted-globals
    self.onmessage = event => {
        // eslint-disable-next-line no-restricted-globals
      try{
        
         function establishUserProcessing(reload:boolean){
            const hHeaders = new Headers();
            hHeaders.append("Accept", "application/json");
            hHeaders.append("Authorization", "Bearer " + event.data.token);
            var fetchOptions:any = {}
            fetchOptions.method = "GET";
            fetchOptions.headers = hHeaders;
            fetch( event.data.APIUrl + "Enquiry/"+event.data.enquiryId+"/Action/KeepAlive", fetchOptions)
            .then(
                response => {
                    if(response.status==204 ){
                        setTimeout(()=>{establishUserProcessing(true)},(60*5*1000));   
                        if(!reload){postMessage({"error":false,"data":"success"});  }
                    }else  if(response.status==400 || response.status==401){
                        postMessage({"error":true,"data":"cannot action the wizard"})
                     }{
            
                }
                })
       
            .catch((err)=>{
               if(reload){
                  postMessage({"error":true,"data":"connection lost"});console.log(err);
               }else{
                  postMessage({"error":true,"data":"unable to connect"});console.log(err);
               }
               
            });
         }
         establishUserProcessing(false);
      }
      catch(err){
       console.table(err)  ;
      }
    }

}

export default keepAlive