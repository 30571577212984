import { useState } from 'react';
import './styles.scss';
import General from './general'
function UserPreference() {
 

    return (
        <div className='userPrefWrapper'>
            <div className="col1">
                <div className="header">Preferences</div>
                <div className="selectedPreference">General</div>
                <div className="preference">Numbers</div>
            </div>
            <div className="col2"><General></General></div>
        </div>
    );
}
export default UserPreference;