import { UpdateAuthTokens, getTenantToken } from "../Logic/Auth/TokenManager";
import { showHideLoader } from "./loading";

export async function http(
    request: RequestInfo
): Promise<any> {
    const response = await fetch(request);
    const body = await response.json();
    return body;
}

export async function Get(url: string): Promise<any> {
    let request: iRequest = {
        url: url,
        method: "GET",
        headers: [],
        body: "",
        responseType: "json"
    }

    return await callApi(request);
}

export async function Delete(url: string): Promise<any> {
    let request: iRequest = {
        url: url,
        method: "DELETE",
        headers: [],
        body: "",
        responseType: "json"
    }

    return await callApi(request);
}

export async function Post(url: string, body: any): Promise<any> {
    let request: iRequest = {
        url: url,
        method: "POST",
        headers: [{ key: "Content-Type", value: "application/json" } ],
        body: JSON.stringify(body),
        responseType: "json"
    }

    return await callApi(request);
}

export async function Put(url: string, body: any): Promise<any> {
    let request: iRequest = {
      url: url,
      method: "PUT",
      headers: [{ key: "Content-Type", value: "application/json" }
      ],
      body: JSON.stringify(body),
      responseType: "json"
    }

    return await callApi(request);
}

export async function callApi(request: iRequest, tenantName?:string): Promise<any> {
    var promise = new Promise<any>((resolve, reject) => {
        showHideLoader("flex");
        let sURL = process.env.REACT_APP_API;
        if (!sURL || sURL.length === 0) {
            var str = window.location;
            let stringtoReplaces =  
                   str.origin.substring(str.protocol.length+2, str.origin.indexOf(".", str.protocol.length+2))
            sURL =  str.origin.replace(stringtoReplaces,"api");
        }
        if (!sURL.endsWith("/")) {
            sURL += "/";
        }

        sURL = sURL+ request.url;
        if (request.url.indexOf("ttps://auth") > 0) {
            sURL = request.url;
        }

        const hHeaders = new Headers();
        for (var x = 0; x < request.headers.length; x++) {
            hHeaders.append(request.headers[x].key, request.headers[x].value);
        }
        hHeaders.append("Authorization", "bearer " + getTenantToken(tenantName));
        var fetchOptions: any = {}
        fetchOptions.method = request.method;
        fetchOptions.headers = hHeaders;
        if (request.method.toLocaleLowerCase() === "post") {
            fetchOptions.body = request.body;
        } else if (request.method.toLocaleLowerCase() === "put" && request.body !== undefined && request.body !== null) {
            fetchOptions.body = request.body;
        }

        fetch(sURL, fetchOptions)

            .then(
                async (data) => {
                    if (data.ok) {
                        if (data.status == 204) {
                            resolve("No Content");
                            showHideLoader("none");
                        }
                        else {
                            resolve(data.json());
                            showHideLoader("none");
                        }
                    }
                    else if (data.status == 400) {
                        (data.text().then((text)=>{
                            reject(data.status + " - " + text);
                            showHideLoader("none");
                        }));
                    }
                    else if (data.status == 401) {
                        UpdateAuthTokens().then(()=>{
                            callApi(request, tenantName).then((data)=>{
                                resolve(data);
                                showHideLoader("none");
                            }
                                );
                        }).catch(()=>{
                            reject(data.status + " - " + data.statusText);
                            showHideLoader("none");
                            console.info(data)
                        });
                       
                    }
                    else {
                        reject(data.status + " - " + data.statusText);
                        showHideLoader("none");
                    }
                }

            ).catch((err) => {
                reject(err);
                showHideLoader("none");
            });
    })
    return promise;
}

export async function callApiArray(request: iRequest): Promise<any> {
    showHideLoader("flex");
    const hHeaders = new Headers();
    for (var x = 0; x < request.headers.length; x++) {
        hHeaders.append(request.headers[x].key, request.headers[x].value);
    }
    // hHeaders.append("TenantId", "EMS_Cloud_Ireland_2");
    try {
        const response = await fetch(request.url, {
            method: request.method,
            headers: hHeaders
        }).then(
            (data) => data.arrayBuffer()

        );
        showHideLoader("none");
        return response
    } catch (err) {
        //  alert(err);
    }
}

 

export async function uploadToS3Bucket(uploadUrl: string, fileByte: any): Promise<any> {
    showHideLoader("flex");
    const hHeaders = new Headers();
    hHeaders.append("Content-Type", "multipart/form-data")
    return new Promise((resolve, reject) => {
        fetch(uploadUrl, {
            method: "PUT",
            headers: hHeaders,
            body: fileByte
        }).then(
            (data) => {
                if (data.ok) {
                    if (data.status == 204) {
                        showHideLoader("none");
                        resolve("No Content");
                    }
                    else if (data.status == 200) {
                        showHideLoader("none");
                        resolve("Ok");
                    }
                    else {
                        showHideLoader("none");
                        resolve(data.json());
                    }
                }
                else {
                    reject(data.status + " - " + data.statusText);
                }
            }

        ).catch((error) => {
            showHideLoader("none");
            reject(error);
        });
    });


}


export async function uploadToS3BucketXHR(uploadUrl: string, fileByte: any, progressId:string): Promise<any> {
    showHideLoader("flex");
    return new Promise((resolve, reject) => {
               var xhr = new XMLHttpRequest();
               xhr.upload.addEventListener("progress", 
               (e)=>{
                ProgressHandler(e, progressId);
               }
               , false);
               xhr.addEventListener("error", (e:any)=>{reject(e)}, false);
               xhr.addEventListener('readystatechange', function(e) {
                    if( this.readyState === 4 ) {
                        resolve(this);
                    }
                });
               xhr.open("PUT", uploadUrl);
               xhr.setRequestHeader("Content-Type", "multipart/form-data");
               var formData = new FormData();
               formData.append("file",fileByte);
               xhr.send(fileByte);
    });
}

const ProgressHandler = (e:any, progressId : string) => {
    var percent = (e.loaded / e.total) * 100;
    let pBar = document.getElementById(progressId) as HTMLDivElement;
    pBar.style.width = Math.round(percent) + "%";
   };

export interface iRequest {
    url: string;
    headers: Array<headerAttribute>;
    body?: string;
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | "HEAD" | "OPTIONS" | "CONNECT" | "TRACE";
    responseType: string
}

export interface iRequestArray {
    url: string;
    headers: Array<headerAttribute>;
    body: ArrayBuffer;
    method: string;
    responseType: string
}

interface headerAttribute {
    key: string,
    value: any
}