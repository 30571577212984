
import Enquiry from '../index';
import * as React from 'react';
import { Col, Row } from "react-bootstrap";
import { AdvancedSearchResults } from "../../../interfaces/AdvancedSearch/AdvancedSearchResults";
import { getEnquiries, getEnquiriesMap, getSessionValue, saveActiveListEnquiries } from '../../../utilities/localStore/calls';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../state/store';
import { useContext, useEffect, useState } from 'react';
import { iLastTouched } from "../../../interfaces/lastTouched";
import { SortEnquiriesParams, sortOwner, sortStatus, sortByField, sortEnquiriesByField } from '../../../utilities/sort';
import { GlobalModalContext } from '../../../contexts/GlobalModalContext';
import { getLastTouched, saveLastTouched } from '../../../Logic/LastTouched/misc';
import EnquiryGrid from '../enquiryGrid';
import { EnquiryGridSelectionContext, gridSelectionProperties } from '../../../contexts/enquiry-grid-selection.context';
function Results(props: any) {
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);     
    const reducedResults = getSessionValue<AdvancedSearchResults>("advancedSearchResults")
        ?.Results
        ?.map((x: number) => x.toString())
        ?.reduce((x: string, y: string) => x + "-" + y, "") ?? "";

    const [Enquiries, setEnquiries] = useState(new Array<any>())
    const LastTouched = getLastTouched();
    let enSort: SortEnquiriesParams = LastTouched.SearchResults.EnquiryLists[0]?.orderBy ?? {orderBy: null};
    const [sortBy, setSortBy] = useState<SortEnquiriesParams>(enSort); 
    const {selectionProperties, SetEnquiryGridSelectionProperties} = useContext(EnquiryGridSelectionContext)
    
    useEffect( () => {
        const searchResults = getSessionValue<AdvancedSearchResults>("advancedSearchResults");
        let enqs = getEnquiries(searchResults?.Results ?? [])
        const LastTouched = getLastTouched();
        const lastSort = LastTouched.SearchResults.EnquiryLists[0].orderBy ?? { orderBy: "dateReceived", desc:true }
        setEnquiries(lastSort.orderBy ?
            sortEnquiriesByField(enqs, lastSort.orderBy, lastSort.desc) :
            enqs
        );
        setSortBy(lastSort);
        saveActiveListEnquiries(enqs.map(enQ => enQ.id))
    }, [enquiriesCache,activeEnquiry]);

    const handleHeaderClick = (fieldName: string): any => {
        const LastTouched = getLastTouched();
        let lastSort = LastTouched.SearchResults.EnquiryLists[0].orderBy ?? {orderBy: null};
        const lastOrderBy = lastSort?.orderBy
    if (lastOrderBy == fieldName) {
            LastTouched.SearchResults.EnquiryLists[0].orderBy = {orderBy: fieldName, desc: !lastSort.desc}
            enSort = {orderBy: fieldName, desc: !lastSort.desc };
        }
        else {
            LastTouched.SearchResults.EnquiryLists[0].orderBy = {orderBy: fieldName}
            enSort = {orderBy: fieldName };
        }
        const order = enSort?.orderBy;
        if (order) {
            if (order == "owner") {
                setEnquiries(es => sortOwner(es, enSort.desc) )
            }
            else if (order == "status") {
                setEnquiries(es => sortStatus(es, enSort.desc) )
            }
            else {
                setEnquiries(es => sortByField(es, order, enSort.desc) )
            }
            LastTouched.SearchResults.EnquiryLists[0].orderBy = {orderBy: order, desc: enSort.desc}
        }
        saveLastTouched(LastTouched);
        setSortBy(enSort)
    }
    const multiSelectOnclick = (selectionProperties:gridSelectionProperties)=>{
        SetEnquiryGridSelectionProperties(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.multiSelect = selectionProperties.multiSelect;
            currentState.selectedEnquiries = selectionProperties.selectedEnquiries;
        return currentState;
        }) ;
    }
    return (
        <>
        {( Enquiries.length > 0) &&
            <EnquiryGrid data={Enquiries} theme="dataTable_default" 
                sortFunction={handleHeaderClick}
                sortCol = {sortBy}
                selectedEnquiry={activeEnquiry.id}
                columns={[ "verticalSPacer",
                             "priority",
                            "hasAlarm",
                            "hasUnworkedAttachments",
                            "enquiryReference",
                            "owner",
                            "enquirerName",
                            "dateReceived",
                            "activeSlaCycle.timeout",
                            "status",
                            "subject",          
                            "dateCompleted",
                            "workItems"       
                        ]}
                        multiSelect={selectionProperties.multiSelect}
                        selectedItems={selectionProperties.selectedEnquiries}
                        multiSelectOnclick={multiSelectOnclick}
            ></EnquiryGrid>
            }
            </>
        )
}

export default Results;