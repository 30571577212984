import { useSelector } from "react-redux";
import { iEnquiry } from '../../../interfaces/iEnquiry';
import './index.scss';
import { Row, Col } from "react-bootstrap";
import Sidebar from '../sidebar/sidebar'
import Topbar from '../topBar/topBar'
import DefaultView from './defaultLayout'
import WorkflowLayout from './workflowLayout'
import Tabs from '../tabRow/tabNavigations' 
import { RootState } from "../../../state/store";
import { getEnquiryById} from "../../../interfaces/enquiryList";
import { ErrorBoundary } from 'react-error-boundary'
import {ErrorFallback} from '../../../error-boundary/errorFallback'
import {defaultReset} from '../../../error-boundary/logerror'
import { EnquiryGridSelectionContext, gridSelectionProperties } from "../../../contexts/enquiry-grid-selection.context";
import { useState } from "react";
import UserPreference from "../../UserPreference";
function FullWidth() {
    const SiteInfo = useSelector((state: RootState) => state.SiteInformation);

   function Showmenu() {
    const box = document.querySelector('.NavItemsE') as HTMLElement | null;
    box?.classList.toggle("expanded");
    alert(111);
  }
 

  function closeAttach() {
    var allElements = document.querySelectorAll('*[id^="mAttach-"]');
    allElements.forEach((item) => { item.className = "MainAttachmentOptionsContainer invisible" });
  }


  
  return (

    <>
<div className="layout">
        <div className="aside">
        <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    > 
          <Sidebar FullWidth={true}/>
      
          </ErrorBoundary>
 
        </div>
        <div className="main-layout">
      
          <div className="top-bar">
          <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
            <Topbar FullWidth={true} />
            </ErrorBoundary>
          </div>
          {   SiteInfo.userPreferanceVisible==true ?
           <UserPreference></UserPreference> : <>
          <div className="paddingForRows">
          <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
                <Tabs FullWidth={true}></Tabs>
                </ErrorBoundary>
          </div>
          <div className="main-content">
            {/* ----------main content---------- */}
            
            <div style={ {padding: "0px"}}>
            {
                <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
                    <WorkflowLayout  FullWidth={true} Popped={false} interface="tab"></WorkflowLayout> 
                    </ErrorBoundary>
            }
            </div>
       
          </div>
          </> }
        
        </div>
      </div>
    </>
  );
}

export default FullWidth;